body {
  background-color: #f4f4f4;
  color: #383838;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  line-height: 1.3em; 
}

.logo {
  width: 100px;
  height: auto;
}

.button {
  background: #ff9603;
  border: 2px solid #ff9603;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 46px;
  display: inline-block;
  padding: 0 35px;
  text-decoration: none;
  transition: all 300ms;
  text-align: center;
  border-radius: 0;
}

.button:hover, 
.button:active, 
.button:focus, 
.button:not(:disabled):not(.disabled).active, 
.button:not(:disabled):not(.disabled):active, 
.show>.button.dropdown-toggle,
.button:not(:disabled):not(.disabled).active:focus, 
.button:not(:disabled):not(.disabled):active:focus, 
.show>.button.dropdown-toggle:focus {
  text-decoration: none;
  background: #f57d1c;
  border-color: #f57d1c;
  color: #fff;
  box-shadow: none;
}

a {
  transition: all 300ms;
  color: #383838;
  text-decoration: none;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
  color: #ff9603;
}

*:focus {
  outline: none;
}

.table {
  color: #383838;
}

.alert {
  background-color: #ff9603;
  color: #fff;
  border-color: #ff9603;
  border-radius: 0;
}

.attribution {
  font-size: smaller;
}

.selected,
.selected:hover,
.selected:focus {
  background-color: #f57d1c !important;
  color: #fff !important;
}

.vehicle-img-container {
  width: 140px;
  height: 66px;
  float: left;
}

.vehicle-img {
  height: 66px;
  float: right;
}

.input-in-between div:first-child,
label[for=inBetween] {
  margin-left: 64px !important;
}

.text-small {
  font-size: 75%;
}

.important-text {
  color: #ff9603;
  font-weight: bold;
  font-size: 18px;
}

.add-remove-btns button:first-child {
  margin-left: 250px;
}

.bridge-num-col {
  width: 18%;
}
