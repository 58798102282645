.inBeetwen {
 padding-left: 30px !important;
 padding-right: 12px !important;
}
.selectRoute{
    padding: 6px;
}
.loading {
    position:absolute;
    margin: 0;
    
    position: absolute;
    top: 50%;
    left: 65%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 500;
}
.loading-addfile {
    position:absolute;
    margin: 0;
    
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 500;
}
table {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-height: 35vh;
}

thead {
    flex: 0 0 auto;
}

tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}
.loading-mlc{
    display: initial !important
}
tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}